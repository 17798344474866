import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/common/Header/Header";
import SearchSection from "./SearchSection";
import "./landing.css";
import Loader from "react-js-loader";
import MapComponent from "./MapNewComponet";
import VenturesShowcase from "./ListAdds";
import { getAdvertisementByFilterS, getAdvertisementS } from "../../Redux/Advertisement/Slice";
import VenturesShowsidercase from "./ListSideAdds";

const locations = [
  "Vizag", "Visakhapatnam", "Vizianagaram", "Pendurthi", "Madhurawada", "Gajuwaka",
  "Srikakulam", "Hyderabad", "Akkayyapalem", "Bhogapuram", "Seethammadhara",
  "Kurmannapalem", "Annavaram", "Anandapuram", "Bheemili", "Pune"
];

const LandingPage = () => {
  const [Adds, setAdds] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [activeTab, setActiveTab] = useState("ventures");
  const [searchValue, setSearchVAlue] = useState("");
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.advertisements);

  useEffect(() => {
    dispatch(getAdvertisementS());
  }, [dispatch]);

  useEffect(() => {
    setAdds(data);
  }, [data]);

  // Function to update the state without calling the API immediately
  const Filter = ({ type, location, search }) => {
  console.log(type);
  
    setSelectedLocation(location.length > 0 ? location : "");
    setActiveTab(type);
    setSearchVAlue(search);

    if (location==='' && search === "") {
      dispatch(getAdvertisementByFilterS({
        type: type,
        city: location,
        search: search
      }));
      
    }
  };



  // Debounce Effect - Calls API after user stops typing
  useEffect(() => {
    if (searchValue  || activeTab && selectedLocation) {
      const handler = setTimeout(() => {
        dispatch(getAdvertisementByFilterS({
          type: activeTab,
          city: selectedLocation,
          search: searchValue
        }));
      }, 1000);

      return () => clearTimeout(handler);
    }
  }, [searchValue, selectedLocation, activeTab, dispatch]);

  return (
    <div>
      <Header />
      <SearchSection
        Filter={Filter}
        Filterdata={Adds}
        handleInputChange={(e) => setSearchVAlue(e.target.value)}
        searchValue={searchValue}
        locationOption={locations}
        selectedLocation={selectedLocation}
        locations={locations}
        setSelectedLocation={setSelectedLocation}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div style={{ alignContent: 'center' }}>
        {loading ? (
          <div className="cards-ads">
            <Loader type="spinner-circle" bgColor="#02486c" color="#02486c" size={80} />
          </div>
        ) : (
       <div className="row" style={{
         padding: '120px 20px 0px'
         }}>
           <div className={selectedLocation !== ""?"col-sm-12 col-md-6":"col-12"}>  
          <MapComponent data={Adds} customStyle={{ height: selectedLocation !== ""? '830px':'500px'}}/>
         </div>
        {selectedLocation !== ""&& <div className="col-sm-12 col-md-6">
      <VenturesShowsidercase data={Adds} title={selectedLocation} filter="Flat" isLoading={loading} />
         </div>}
        
        </div>
        )}
      </div>
      <VenturesShowcase data={Adds} title="Explore our latest Ventures" filter="Flat" isLoading={loading} />
      <VenturesShowcase data={Adds} title="Explore our latest Agriculture lands" filter="Agriculture Land" isLoading={loading} />
    </div>
  );
};

export default LandingPage;
