import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAdvertisementByFilter,getAdvertisement } from './Api';

const AsyncFunctionThunk = (name, apiFunction) => {
    return createAsyncThunk(`Script/${name}`, async (data, { rejectWithValue }) => {
      try {
        const response = await apiFunction(data);
  
  
        return response.data;
      } catch (error) {
  
  
        if (error.response && error.response.data) {
  
          return rejectWithValue(error.response.data);
        }
  
        return rejectWithValue({ error: error.message });
        throw error;
      }
    });
  };


  export const getAdvertisementByFilterS = AsyncFunctionThunk('GetScriptByIDs', getAdvertisementByFilter);
  export const getAdvertisementS = AsyncFunctionThunk('getAdvertisementS', getAdvertisement);

// Create a slice for managing advertisements state
const advertisementSlice = createSlice({
  name: 'advertisements',
  initialState: {
    data: [],
    Filterdata: [],
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdvertisementByFilterS.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAdvertisementByFilterS.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAdvertisementByFilterS.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Error fetching advertisements';
      })
      .addCase(getAdvertisementS.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAdvertisementS.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAdvertisementS.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Error fetching advertisements';
      });
  }
});

export default advertisementSlice.reducer;




 