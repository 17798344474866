"use client";

import React, { useState } from "react";

export default function VerificationForm({
  phone,
  name,
  plotNumber,
  amount,
}) {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = React.useRef([]);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = (index, value) => {
    setError(false); // Clear error on input change
    if (!/^\d*$/.test(value)) return; // Only allow numeric input

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input if value is entered
    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index, e) => {
    // Move to the previous input on backspace
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const binaryOTP = localStorage.getItem("otpBinary");
    if (binaryOTP) {
      const otps = parseInt(binaryOTP, 2); // Convert binary back to decimal
      const fillOtp = otp.join(""); // Combine OTP inputs

      if (otps === parseInt(fillOtp)) {
        // Prepare data for submission
        const Details = {
          name: name,
          mail: "",
          message: JSON.stringify({
            plotnumber: plotNumber,
            amount: amount,
            enquiry: "plot booking",
          }),
          phone: phone,
        };

        try {
          const response = await fetch(
            "https://backend.myplotpic.com/api/addEnquiry",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: JSON.stringify(Details),
            }
          );

          if (response.ok) {
            console.log("Details submitted successfully!");
            setSuccess(true);
            setTimeout(() => {
              // Close modal after 1 second
              window.location.reload()
            }, 1000);
          } else {
            console.error("Failed to submit details.");
          }
        } catch (error) {
          console.error("Error submitting details:", error);
        }
      } else {
        setError(true);
      }
    }
  };

  const handleResend = async () => {
    try {
      const response = await fetch(
        "https://backend.myplotpic.com/api/sendOTPtoContectNumberWOLogin",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ contectNumber: phone }),
        }
      );

      const res = await response.json(); // Parse the JSON response
      if (res.success) {
        const otp = res.data.OTP; // Extract OTP
        const binaryOTP = otp.toString(2); // Convert to binary
        localStorage.setItem("otpBinary", binaryOTP);
        console.log("OTP Resent Successfully!");
      } else {
        console.error("Failed to resend OTP.");
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="verificationModal"
        tabIndex={-1}
        aria-labelledby="verificationModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content verification-modal">
            <div
              className="modal-header d-flex justify-content-center"
              style={{ borderBottom: "none" }}
            >
              <h5 className="modal-title text-center" id="verificationModalLabel">
                Verification
              </h5>
            </div>
            <div className="modal-body">
              <p className="text-muted text-center mb-4">
                OTP has been sent to your mobile number. Please enter the code
                below to verify your account.
              </p>
              <form onSubmit={handleSubmit}>
                <div className="d-flex justify-content-center gap-3 mb-3">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      ref={(el) => (inputRefs.current[index] = el)}
                      type="text"
                      inputMode="numeric"
                      maxLength={1}
                      value={digit}
                      onChange={(e) => handleChange(index, e.target.value)}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                      className="form-control text-center border border-secondary"
                      style={{
                        width: "50px",
                        height: "50px",
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                      }}
                      aria-label={`Digit ${index + 1}`}
                    />
                  ))}
                </div>
                <div className="text-center mb-3">
                  <small className="text-muted">
                    Didn't receive OTP?{" "}
                    <button
                      type="button"
                      onClick={handleResend}
                      className="btn btn-link p-0 text-primary"
                    >
                      Resend OTP
                    </button>
                  </small>
                </div>

                {error && (
                  <div>
                    <p className="text-danger text-center mb-0">
                      OTP Invalid! Try again.
                    </p>
                  </div>
                )}

                {success && (
                  <div>
                    <p className="text-success text-center mb-0">
                      Verified successfully!
                    </p>
                  </div>
                )}

 

                <div className='mx-auto d-flex justify-content-center'>

<button
  type="button" // Change type to button to avoid default form submission
  
  onClick={handleSubmit}
  className="btn sumt-btn mt-0 btn-warning w-100 text-uppercase fw-bold"
  >
  Submit
</button>
</div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
