import React from 'react'
import Logo from '../../../assets/landing-page/logo.svg'
import './Header.css'

const Header = () => {
  return (
  
        
        <div className='header-top d-flex justify-content-center align-items-end p-3'>
            <img src={Logo} alt="" srcset="" />
        </div>
   
  )
}

export default Header