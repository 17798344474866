import React, { useRef, useState } from 'react'
import { Dropdown, InputGroup, FormControl, Button as BootstrapButton } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
 

const SearchSection = ({Filter,searchValue,handleInputChange,Filterdata,selectedLocation,locationOption,locations, setSelectedLocation, activeTab, setActiveTab}) => {
 const history =useHistory()
 
  const handleDetails = (item) => {
    //console.log(item);
    
    window.localStorage.clear();
    window.localStorage.setItem("json", JSON.stringify(item));
     history.push("/" + item.typeProperty);
  };
  return (
    <div
    className='SearchSection-main'
    >
        
<div className='container-wrap'>
<h1 className='main-heading'>
Get the best properties with less efforts
</h1>

<div>
<div className="w-100   p-4">
      <div className="container">
        {/* Tabs */}
        <div className="d-flex ">
          <button
            onClick={() =>{Filter({type:'ventures',location:'',search:''})}}
            className={` ms-2 me-3  upper-tab  my-0 ${
              activeTab === "ventures" ? "btn-light" : "btn-outline-light"
            }`}
          >
            Ventures
          </button>
          <button
            onClick={() =>{Filter({type:'Agriculture Land',location:'',search:""})}}
            className={` ms-lg-5 my-0 upper-tab ${
              activeTab === "Agriculture Land" ? "btn-light" : "btn-outline-light"
            }`}
          >
            Agriculture Lands
          </button>
        </div>

        {/* Search Bar */}
        <div className="d-flex ">
          <Dropdown>
            <Dropdown.Toggle className="m-0  drop-btn"   id="dropdown-basic">
              {selectedLocation === ''? "All":selectedLocation}
            </Dropdown.Toggle>

            <Dropdown.Menu style={{
                  height: '300px',
                  overflowY: 'auto',
                  scrollbarWidth: 'none', // For Firefox
                  msOverflowStyle: 'none' // For Internet Explorer and Edge
            }}
            className="custom-scroll"
            
            >
              {locationOption.map((location,index) => (
                <Dropdown.Item
                className="drop-menu"
                  key={index}
                  onClick={() => Filter({type:activeTab,location:location,search:''})}
                >
                  {location}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
         

          <InputGroup>
            <FormControl
              type="text"
              placeholder="Search by location"
              onChange={handleInputChange}
              className="search-input"
            />
{searchValue?.length>0 &&
<div className='serchDrop custom-scroll'> 
<ul>
  {Filterdata.map((i)=>(<>

    <li onClick={()=>handleDetails(i)}>{i?.adTitle}</li>
  </>

  ))}
</ul>
          </div>}
            </InputGroup>

            
        </div>
      </div>
    </div>
</div>
</div>


    </div>
  )
}

export default SearchSection