import React from 'react';
import ReactDOM from 'react-dom/client'; // Use 'react-dom/client' instead of 'react-dom'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'font-awesome/css/font-awesome.min.css';
import { Provider } from 'react-redux';
import store from './Store';

// Create a root element using 'createRoot'
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(  // Use 'root.render' instead of 'ReactDOM.render'
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// Measure performance
reportWebVitals();
