import "bootstrap/dist/css/bootstrap.min.css";
import img from "./dummyland.png";
import Skeleton from "react-loading-skeleton";  
import "react-loading-skeleton/dist/skeleton.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function VenturesShowsidercase({ data, title, filter, isLoading }) {
  const endpoint = "https://backend.myplotpic.com/uploads/";
  const filterarr = data.filter((i) => i.typeProperty === filter) || [];
const getbuilderNAme= (item)=>{
const json = JSON.parse(item)
 
return json[0]?.name.slice(0,12)
}


 const history =useHistory()
 
  const handleDetails = (item) => {
    //console.log(item);
    
    window.localStorage.clear();
    window.localStorage.setItem("json", JSON.stringify(item));
     history.push("/" + item.typeProperty);
  };
  return (
    <section className=" container">
      <div className="row g-4 align-items-center custom-scroll" style={{
        height: '830px',
        overflowY: 'auto'
      }}>

  <h2 className="text-left  mb-4">Plots in {title}</h2>
        {isLoading
          ?  
            [...Array(4)].map((_, index) => (
              <div key={index} className="col-12">
                <div className="card w-100 sideFIlter">
                  <div style={{ width: "100%", height: "195px" }} className="text-center">
                    <Skeleton height={225} />
                  </div>
                  <div className="card-body text-center">
                    <h5 className="card-title">
                      <Skeleton width="80%" />
                    </h5>
                    <p className="card-text">
                      <Skeleton width="60%" />
                    </p>
                    <Skeleton width={120} height={30} />
                  </div>
                </div>
              </div>
            ))
          :  
            filterarr.map((venture) => (
              <div key={venture.id} className="col-12 p-0">
                <div className="card w-100 sideFIlter">
                  <div style={{ width: "263px", height: "195px" }} className="text-center">
                  <img
  src={venture?.files.length > 0 ? endpoint + venture?.files[0] : img}
  className="card-img-top"
  alt="..."
  style={{ width: "100%", height: "100%", objectFit: "cover" }}
  onError={(e) => { e.target.onerror = null; e.target.src = img; }} 
/>
                  </div>
                  <div className="card-body text-center">
                    <h5 className="card-title-value" onClick={()=>handleDetails(venture)}>{venture?.adTitle.slice(0, 50)}</h5>
                    <div className="d-flex justify-content-between py-2">
                    <p className='mb-0 card-text'>Number of plots </p>
                    <p className='mb-0 text-bold-value' >{venture?.adId}</p>

                    </div>
                    <div className="d-flex justify-content-between py-2">
                    <p className='mb-0 card-text'>Plot size starts </p>
                    <p className='mb-0 text-bold-value' >200ft</p>

                    </div>
                    <div className="d-flex justify-content-between py-2">
                    <p className='mb-0 card-text'>Developer </p>
                    <p className='mb-0 text-bold-value' >{getbuilderNAme(venture?.builder[0])}</p>

                    </div>
                  </div>
                </div>
              </div>
            ))}
      </div>
    </section>
  );
}
