import axios from 'axios';
import { baseurl } from '../../utils/BaseUrl';


export const getAdvertisementByFilter = async (data)=>{
    const { type, city, search}= data
    try {
        const response = await axios.get(`${baseurl}api/advertisements`, {
          params: { type, city, search }
        });
        return response.data; // Return the fetched data
      } catch (error) {
        return error;
      }
}

 

export async function getAdvertisement() {
 

  try {
    const response = await fetch(`${baseurl}api/getAdvertisement`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    console.log("Advertisement Data:", data);
    return data;
  } catch (error) {
    console.error("Error fetching advertisement data:", error);
    throw error;
  }
}