import "bootstrap/dist/css/bootstrap.min.css";
import img from "./dummyland.png";
import Skeleton from "react-loading-skeleton";  
import "react-loading-skeleton/dist/skeleton.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function VenturesShowcase({ data, title, filter, isLoading }) {
  const endpoint = "https://backend.myplotpic.com/uploads/";
  const filterarr = data.filter((i) => i.typeProperty === filter) || [];


   const history =useHistory()
   
    const handleDetails = (item) => {
      //console.log(item);
      
      window.localStorage.clear();
      window.localStorage.setItem("json", JSON.stringify(item));
       history.push("/" + item.typeProperty);
    };
  return (
    <section className="py-5 container">
     {filterarr.length>0 && <h2 className="text-center mb-4">{title}</h2>}
      <div className="row g-4 align-items-center">
        {isLoading
          ?  
            [...Array(8)].map((_, index) => (
              <div key={index} className="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xx-2">
                <div className="card w-100">
                  <div style={{ width: "100%", height: "225px" }} className="text-center">
                    <Skeleton height={225} />
                  </div>
                  <div className="card-body text-center">
                    <h5 className="card-title">
                      <Skeleton width="80%" />
                    </h5>
                    <p className="card-text">
                      <Skeleton width="60%" />
                    </p>
                    <Skeleton width={120} height={30} />
                  </div>
                </div>
              </div>
            ))
          :  
            filterarr.slice(0, 8).map((venture) => (
              <div key={venture.id} className="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xx-2">
                <div className="card  w-100">
                  <div style={{ width: "100%", height: "225px" }} className="text-center">
                  <img
  src={venture?.files.length > 0 ? endpoint + venture?.files[0] : img}
  className="card-img-top"
  alt="..."
  style={{ width: "100%", height: "100%", objectFit: "cover" }}
  onError={(e) => { e.target.onerror = null; e.target.src = img; }} 
/>
                  </div>
                  <div className="card-body text-center">
                    <h5 className="card-title" onClick={()=>handleDetails(venture)}>{venture?.adTitle.slice(0, 17)}</h5>
                    <p className="card-text">{venture?.city}</p>
                    <button className="view-Detail" onClick={()=>handleDetails(venture)}>View Details</button>
                  </div>
                </div>
              </div>
            ))}
      </div>
    </section>
  );
}
