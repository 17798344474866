import { configureStore } from '@reduxjs/toolkit';
import advertisementReducer from './Redux/Advertisement/Slice';

// Configure the store with our reducers
const store = configureStore({
  reducer: {
    advertisements: advertisementReducer
  }
});

export default store;