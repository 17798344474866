import React, {useState, useEffect} from 'react'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, } from '@react-google-maps/api';
import homeIcon from '../../assets/icon/home.png';
import axios from 'axios';
import './Map.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// const google = window.google
import flat from './offices.png'
import agriLand from './Group 358.png'
import plot from './Group 364.png'

const containerStyle = {
  width: '88%',
  height: '500px',
  marginLeft: "6%",
  marginTop: "3%",
  borderRadius: 1
};


const mapOptions = {
  zoom: 10,
  center: { lat: 17.6868, lng: 83.2185 },
  // styles: [
  //   {
  //     featureType: 'poi',
  //     stylers: [{ visibility: 'off' }],
  //   },
  // ],
};


const customIcon = {
  url: "https://i.ibb.co/7v877vm/marker.png",
  // scaledSize: new google.maps.Size(70, 70),
  // size:new google.maps.Size(70, 70)
};

function MapComponent(props) {
  const { locationProps,data,customStyle } = props;

 
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBttNovQQfTde-VkUHOugPWUrgr9DkmmaU"
  })
  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [map, setMap] = React.useState(null);
  const [infoWindowData, setInfoWindowData] = useState();
  const [markers, setMarkers] = useState(props);
  
  const history =useHistory()
  const [position, setPosition] = useState({
    lat: 17.6868, 
    lng: 83.2185
  });
 

  const handleDetails = (item) => {
    //console.log(item);
    
    window.localStorage.clear();
    window.localStorage.setItem("json", JSON.stringify(item));
     history.push("/" + item.typeProperty);
  };
  useEffect(() => {
    console.log(data,'data');
    if (data.length > 0) {
      setMarkers(data)
      
      let obj = {
        lat: data[0].lat,
        lng: data[0].lng,
      }
      setPosition(obj)
    }
  }, [data]);




  const onLoad = React.useCallback(function callback(map) {
    // let obj = {
    //   lat: markers.locationProps[0].lat,
    //   lng: markers.locationProps[0].lng,
    // }

    // //console.log(obj);
    
    // const bounds = new window.google.maps.LatLngBounds(obj);
    // map.fitBounds(bounds);


    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  
  const handleMarkerClick = (id, lat, lng, adTitle,matchingData) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, adTitle ,...matchingData});
    setIsOpen(true);
  };

  // const handleNavigationClick = () => {
  //   navigate(`/property-details/${infoWindowData.id}`); // Update with your route
  // };

  const handleDirectionClick = (lat, lng) => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    window.open(url, "_blank");
  };

  const handleCallClick = (phoneNumber) => {    
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleWhatsAppClick = (phoneNumber) => {
    window.open(`https://wa.me/${phoneNumber}`, "_blank");
  };

  const handleNavigationClick = (n) => {
    //console.log(12);
    //console.log(n);
    
    // navigate(`/property-details/${infoWindowData.id}`); // Update with your route
  };

  const gettype=(value)=>{
      
      if (value=== 'Agriculture Land' || value === 'Agricultural Land') {
    return agriLand
}
else if (value=== 'Plot') {
    return plot
}
else{
    return  plot
}
  }
 

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={customStyle ?customStyle:containerStyle}
        center={position}
        options={mapOptions}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <>
          {markers.map(({ adTitle,adId, lat, lng, contactNumber,typeProperty}, key) => (
            <>
              {
                markers.length > 0 ?
                <>
                <Marker
                    key={key}
                    position={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
                    icon={{
                      url: gettype(typeProperty),
                      scaledSize: new window.google.maps.Size(72, 72),
                  }}
                    // icon={customIcon}
                    onClick={() => {
                    const  matchingData = data.find(dataItem => dataItem.adTitle === adTitle);
                    
                    
                      handleMarkerClick(key, lat, lng, adTitle,matchingData);
                    }}
                  >
                    {isOpen && infoWindowData?.id === key && (

                      <InfoWindow
                      options={{ minWidth: 200 }}
                      onCloseClick={() => setIsOpen(false)}

                      >
                      <div className='info-window'>
                        <p style={{
                        cursor:'pointer',
                        marginLeft:'20px'
                      }}>Listing ID:{adId}</p>

                      <h5 className='hover-link'
                      onClick={() =>handleDetails(infoWindowData)}
                      style={{
                        cursor:'pointer',
                        marginLeft:'20px'
                      }}> 
            
              <  >
                {infoWindowData.adTitle}
              </>
           
          </h5>
                      <p  style={{
                        cursor:'pointer',
                        marginLeft:'20px'
                      }} >{infoWindowData.propertyAddress}</p>
                      <p  style={{
                        cursor:'pointer',
                        marginLeft:'20px'
                      }}>{infoWindowData.startingPrice}</p>
                      <p style={{
                        cursor:'pointer',
                        marginLeft:'20px'
                      }} >{infoWindowData.furnish}</p>
                <div className='info-window-buttons'>
 
                  {lat && lng && (

 

                    <button onClick={() => handleDirectionClick(lat, lng)}>
                     <span className='span-icon' style={{
                      marginRight:'10px'
                     }}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20" fill="white">
    <path d="M227.7 11.7c15.6-15.6 40.9-15.6 56.6 0l216 216c15.6 15.6 15.6 40.9 0 56.6l-216 216c-15.6 15.6-40.9 15.6-56.6 0l-216-216c-15.6-15.6-15.6-40.9 0-56.6l216-216zm87.6 137c-4.6-4.6-11.5-5.9-17.4-3.5s-9.9 8.3-9.9 14.8l0 56-64 0c-35.3 0-64 28.7-64 64l0 48c0 13.3 10.7 24 24 24s24-10.7 24-24l0-48c0-8.8 7.2-16 16-16l64 0 0 56c0 6.5 3.9 12.3 9.9 14.8s12.9 1.1 17.4-3.5l80-80c6.2-6.2 6.2-16.4 0-22.6l-80-80z"/>
</svg>
</span> 

                      </button>
                  )}
                  {contactNumber && (
                    <>
                      <button onClick={() => handleCallClick(contactNumber)}>
                      <span className='span-icon' style={{
                      marginRight:'10px'
                     }}>
                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20" fill="white">
    <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/>
</svg>

</span> 
                        
                        </button>
                      <button onClick={() => handleWhatsAppClick(contactNumber)}>
                      <span className='span-icon' style={{
                      marginRight:'10px'
                     }}>
                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="20" height="20" fill="white">
    <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/>
</svg>

</span> 
                        
                        </button>
                    </>
                  )}
                </div>
                      </div>
                      </InfoWindow>

                      // <InfoWindow
                      //   options= {{minWidth : 200 }}
                      //   onCloseClick={() => {
                      //     setIsOpen(false);
                      //   }}
                      // >
                      //   <p className='markerTxt' onClick={() => window.open("https://myplotpic.com/details?ad=00056", "_blank")}>{infoWindowData.adTitle}</p>
                      // </InfoWindow>
                    )}
                  </Marker>
                </>
                :
                <></>
              }
            </>
          ))}
        </>
      </GoogleMap>
    </>
    
  ) : <></>
}

export default React.memo(MapComponent)
