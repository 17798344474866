import React, { useEffect } from 'react';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { PagesTimeline } from 'polotno/pages-timeline';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { ElementsSection, LayersSection, SidePanel, SizeSection, TextSection, UploadSection } from 'polotno/side-panel';
import { Workspace } from 'polotno/canvas/workspace';
 import './Editer.css'
import { createStore } from 'polotno/model/store';
import { useLocation } from 'react-router-dom';
import { baseurl } from '../../utils/BaseUrl';
import Navbar from '../../components/NavBar';

export const Editor = () => {
  const store = createStore({
    key: '',  
    showCredit: true,  
  });

  const page = store.addPage();
  const location = useLocation();  
  console.log(location);

   
  const queryParams = new URLSearchParams(location.search);
  const filePath = queryParams.get('path');  

  async function fetchImage() {
    if (!filePath) return;  

     
    const extractedFileName = filePath.split('/').pop();

    try {
      const response = await fetch(`${baseurl}api/image/${extractedFileName}`);
      if (!response.ok) throw new Error('Failed to fetch image');

      const blob = await response.blob();  
      const imageUrl = URL.createObjectURL(blob);  

      
      const img = new Image();
      img.src = imageUrl;

      img.onload = () => {
        const imageWidth = img.naturalWidth;
        const imageHeight = img.naturalHeight;

        console.log('Image dimensions:', imageWidth, imageHeight);

        page.set({
          custom: { myInternalId: 'some-id-here' },
          bleed: 10, 
          width: imageWidth, 
          height: imageHeight,  
        });

        
        page.addElement({
          type: 'image',
          x: 0,
          y: 0,
          rotation: 0,
          locked: false,
          blurEnabled: false,
          blurRadius: 10,
          brightnessEnabled: false,
          brightness: 0,
          shadowEnabled: false,
          shadowBlur: 5,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowColor: 'black',
          shadowOpacity: 1,
          name: '',
          src: imageUrl,
          keepRatio: true,
          width: imageWidth,
          height: imageHeight,
          cropX: 0,
          cropY: 0,
          cropWidth: 1,
          cropHeight: 1,
          cornerRadius: 0,
          borderColor: 'black',
          borderSize: 0,
          flipX: false,
          flipY: false,
          selectable: true,
          alwaysOnTop: false,
          showInExport: true,
          draggable: true,
          contentEditable: true,
          removable: true,
          resizable: true,
        });
      };
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  }

  useEffect(() => {
    fetchImage();
  }, [filePath]); // Re-run the effect when the filePath changes

  const sections = [UploadSection, TextSection, ElementsSection, LayersSection, SizeSection];

  return (<div>
    <div>
    <Navbar />
    </div>
    <PolotnoContainer style={{ width: '100vw', height: '100vh' }}>
      <SidePanelWrap>
        <SidePanel store={store} sections={sections} defaultSection="custom" />
      </SidePanelWrap>
      <WorkspaceWrap>
        <Toolbar store={store} downloadButtonEnabled />
        <Workspace store={store} />
        <ZoomButtons store={store} />
        <PagesTimeline store={store} />
      </WorkspaceWrap>
    </PolotnoContainer>
  </div>
  );
};
